<div class="mainContent">
    <div class="card-item wine-card" [routerLink]="'/wine/' + wine.id">
        <img class="wine-image" src="{{ wine.imagePath }}" />
        <div class="card-item-middle">
            <div>
                <div class="card-item-label wine-card-item-label">
                    {{wine.name}}
                </div>
                <div class="gray">
                    {{wine.area}}
                </div>
            </div>
            <div class="wine-color">
                <div [style.background]="wine.color" class="color-icon"></div>
                <span class="color-label">{{ wine.varietal }}</span>
            </div>
        </div>
    </div>
    <div class="card-item gray-card location">
        <div>
            Located in Slot {{getSlot()}} in the <a [routerLink]="'/storage-detail/' + storageLocation.id">{{storageLocation.name}}</a>    
        </div>
    </div>
    <div class="event-detected-count">
        {{this.events.length}} events detected
    </div>
    <div class="dividing-row"></div>
    <div class="post-event-count-paddding"></div>
    <div class="event-list">
        @for(event of this.events; track event.id) {
            <div class="event-detail">
                <div>{{event.eventDetail}}</div>
                <div class="gray lowercase">
                    {{eventTimeMap[event.id]}}
                </div>
            </div>
            <div class="dividing-row"></div>
        }
    </div>
</div>