import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = 'auth';

  constructor(private http: HttpClient) { }

  register(user: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/register`, user);
  }

  login(credentials: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/login`, credentials);
  }

  forgotPassword(credentials: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/forgotPassword`, credentials);
  }

  resetPassword(credentials: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/resetPassword`, credentials);
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("access_token") !== null;
  }

  isIncomplete(): boolean {
    return localStorage.getItem("incomplete") !== null && localStorage.getItem("incomplete") == 'true';
  }

  addSubAccount(email: string): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/addSubAccount`, email);
  }
  removeSubAccount(id: number): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/removeSubAccount`, id);
  }
}