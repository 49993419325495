<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form (ngSubmit)="register()">
            <span class="error text-danger">{{error}}</span>
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input type="text" class="form-control" id="firstName" placeholder="John" [(ngModel)]="user.firstName" name="firstName">
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input type="text" class="form-control" id="lastName" placeholder="Doe" [(ngModel)]="user.lastName" name="lastName">
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" placeholder="example@email.com" [(ngModel)]="user.email" name="email">
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input [type]="(showPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="password" [(ngModel)]="user.password" name="password">
              <img [src]="'/assets/' + (showPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showPasswordType")' class="eye-svg" />
            </div>
            <div class="form-group">
              <label for="password">Confirm Password</label>
              <input [type]="(showConfirmPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="passwordConfirm" [(ngModel)]="passwordConfirm" name="passwordConfirm">
              <img [src]="'/assets/' + (showConfirmPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showConfirmPasswordType")' class="eye-svg" />
            </div>
            <div class="form-group">
              <button type="submit" class="btn cc-btn col-12 mt-3">Create Account</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>