import { Component, ViewChild } from '@angular/core';
import { HeaderOptions } from '../header/header.component';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { numberToLetter } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { WineGridBoxComponent } from "../wine-grid-box/wine-grid-box.component";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-storage-detail',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    WineGridBoxComponent
],
  templateUrl: './storage-detail.component.html',
  styleUrl: './storage-detail.component.scss'
})
export class StorageDetailComponent {
  resetSelectedDepthSub: Subject<void> = new Subject();
  constructor(private route: ActivatedRoute,private fb: FormBuilder,private _sharedService: SharedService){}
  form!: FormGroup;
  storageLocation:any = {};
  storageLocationId:number = 0;
  customType:boolean = true;
  selectedDepth: number =1;

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
    let unitId = this.route.snapshot.paramMap.get("id") ?? "";
    if(unitId == ""){
      this.storageLocation.rows = 3;
      this.storageLocation.columns = 5;
      this.storageLocation.depth = 1;
      this.storageLocation.stacked = true;
    }
    else{
      //TODO Make call to load unit details
    }
    
    this._sharedService.setData({ body: "", title: "Manage Storage", image: "", backFunction: false });
  }

    decrimentNumber(key:string){
      this.storageLocation[key]--;
      this.validateMin(key);
    }

    incrementNumber(key:string){
      this.storageLocation[key]++;
      this.validateMin(key);
    }

    validateMin(key:string){
      let min = ((key == 'columns' || key == 'rows') && !this.storageLocation.stacked) ? 2 : 1;
      if(this.storageLocation[key] < min){
        this.storageLocation[key] = min;
      }
    }
    resetSelectedDepth(){
      this.resetSelectedDepthSub.next();
    }

}
