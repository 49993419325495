<div class="modal" id="{{ modalId }}" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button
          type="button"
          class="close-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        ><img src="/assets/close.svg" /></button>
      </div>
      <div class="modal-body">
        <div [innerHTML]="modalBody">
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="displayNoButton"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          {{ noButtonText }}
        </button>
        <button
          type="button"
          class="btn cc-btn"
          (click)="yesClick()"
          data-bs-dismiss="modal"
        >
          {{ yesButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
