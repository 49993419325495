import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private yesClick$ = new Subject<any>();
  yesClick = this.yesClick$.asObservable();

  constructor() { }

  setYesClicked(data:any): void {
    this.yesClick$.next(data);
  }
}
