import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { HeaderOptions } from '../header/header.component';
import { FormBuilder } from '@angular/forms';
import { CommonModule, KeyValue, Location } from '@angular/common';
import { SearchService } from '../../services/search.service';
import { FormsModule } from '@angular/forms';
import { WineService } from '../../services/wine.service';
import { debounceTime, interval, Subject } from 'rxjs';
import { SortedWineListComponent } from "../sorted-wine-list/sorted-wine-list.component";
import { WineFilterService } from '../../services/wine-filter.service';

@Component({
  selector: 'app-search-results',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, SortedWineListComponent],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss'
})


export class SearchResultsComponent {


private subject: Subject<string> = new Subject();
displayProducerResults = false;
producerId: string = "";

//,private fb: FormBuilder,, 
  constructor(private elRef: ElementRef, private _wineFilterService: WineFilterService, private router: Router, private _searchService: SearchService, private _wineService: WineService, private route: ActivatedRoute,private _sharedService: SharedService,private fb: FormBuilder,private location: Location){}

  ngOnInit(): void {

    this._wineFilterService.resetData();
    this.elRef.nativeElement.querySelector('#input').focus();
    this.producerId = this.route.snapshot.paramMap.get("id") ?? "";

    this.subject.pipe(debounceTime(1000))
      .subscribe(
        value => {
          this.updateQuerySimple();
        }
      )

    this._sharedService.setData({ type: HeaderOptions.noImageSmallBack, body: "", title: "Search", image: "", backFunction: true });
  }

  // this is called each time a user
  // updates their query without pressing
  // backspace
  updateQuerySimple() {
    this.searchService.resetData();
    this.wineFilterService.resetData();
    this.displayProducerResults = false;
    this.wineService.getSearchResults(this.searchService.query).subscribe({
      next: (response) => {
        this.searchService.presortedProducerList = response['producerList'];
        this.searchService.presortedWineList = response['wineList'];
        this.wineFilterService.wineList = response['wineList'];
        this.searchService.sortResultList();
        this.wineFilterService.filterWine();
      }, 
      error: (e) => {
        console.log("Error", e);
        this.searchService.resetData();
        this.wineFilterService.resetData();
      }
    });
  }

  // this is called when the user presses enter
  // that displays advanced details of all
  // wines and producers form our search results
  updateQueryAdvanced() {
    if (this.displayProducerResults==false) {
      this.displayProducerResults=true;
    } else {
      this.displayProducerResults=false;
    }
  }

  onKeyDown(event: KeyboardEvent) {
    console.log('press');
    if (event.key == 'Enter') {
      this.updateQueryAdvanced();
    } else {
      this.subject.next(this.searchService.query);
    }
  }

  goBack():void{
    this.searchService.resetData();
    this.location.back();
  }

  get searchService(): SearchService {
    return this._searchService;
  }

  get wineService(): WineService {
    return this._wineService;
  }

  get wineFilterService(): WineFilterService {
    return this._wineFilterService;
  }

  get searchResults(): any {
    return this.searchService.searchResults;
  }

}
