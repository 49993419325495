import { Component } from '@angular/core';
import { Router, NavigationEnd,RouterModule } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-footer-nav',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer-nav.component.html',
  styleUrl: './footer-nav.component.scss'
})
export class FooterNavComponent {

  constructor(private router: Router,private _userService: UserService) {}

  public showNav: boolean = false;
  public currentRoute: string = "";
  public withoutInRouteParams: string = "";

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var length = event.url.indexOf("?");
        var lastSlash = event.url.lastIndexOf('/');
        if(length == -1){
          length = event.url.length;
        }
        if(lastSlash == -1){
          lastSlash = event.url.length;
        }
        this.currentRoute = event.url.substring(0,length);
        this.withoutInRouteParams = event.url.substring(0,lastSlash);
        //most routes will want the nav bar, this is the list where it shouldnt appear
        switch(this.currentRoute){
          case "/login":
          case "/":
          case "/register":
          case "/forgot-password":
          case "/reset-password":
          case "/complete-profile":
            this.showNav = false;
            break;
          default:
            this.showNav = true;
            break;
        }
      }
    });
  }
}
