<div [hidden]="!showNav">
    <div class="footer-bar">
        <a class="icon-group" routerLink="/home">
            <img [src]="'/assets/' + (currentRoute == '/home' ? 'nav-home-filled.svg' : 'nav-home.svg')" />
        </a>
        <a class="icon-group" routerLink="/collection">
            <img [src]="'/assets/' + (currentRoute == '/collection' ? 'nav-collection-filled.svg' : 'nav-collection.svg')" />
        </a>
        <a class="icon-group" routerLink="/activity">
            <img [src]="'/assets/' + (currentRoute == '/activity' || withoutInRouteParams == '/activity-detail' ? 'activity-selected.svg' : 'nav-activity.svg')" />
        </a>
        <a class="icon-group" routerLink="/storage-list">
            <img [src]="'/assets/' + (currentRoute == '/storage-list' ? 'nav-storage-selected.svg' : 'nav-storage.svg')" />
        </a>
        <a class="icon-group disabled">
            <img src='/assets/nav-places.svg' />
        </a>
    </div>
    <div class="footer-shadow"></div>
    <div class="footer-padding"></div>
</div>
