import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router,RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { DecimalPipe, Location } from '@angular/common';
import { WineListComponent } from '../wine-list/wine-list.component';
import { HeaderOptions } from '../header/header.component';
import { FormsModule } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { WineService } from '../../services/wine.service';
import { ActivityListComponent } from "../activity-list/activity-list.component";
import { ActivityService } from '../../services/activity.service';
import { ScrollService } from '../../services/scroll.service';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [DecimalPipe, WineListComponent,RouterModule,FormsModule, ActivityListComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  public winecount: number = 0;
  public featuredWines: any = [];
  public recentActivity: any = [];
  public wineName: string = "";

  //possibly change this from only showing the 3 most recent events
  public maxNumb = 3;

  constructor(private wineService: WineService, private _searchService: SearchService,private authService: AuthService,private _activityService: ActivityService, private router: Router, private _sharedService: SharedService,private _wineService: WineService, private _userService: UserService,private _scrollService: ScrollService) { }

  ngOnInit(): void {
    this._sharedService.setData({body: "Good Evening!", title: "Search for Wine", image: "header4.png" });
    this._userService.getUser().subscribe({
      next: (response) => {
        console.info(response);
      }, 
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getWineCounts().subscribe({
      next: (response) => {
        this.winecount = Object.keys(response).length;
      }, 
      error: (e) => {
        console.error(e);
      }
    });

    this._activityService.getActivity(this.maxNumb).subscribe({
      next: (response) => {
        this.recentActivity = response;
        this._scrollService.setMax(response.length);
      }, 
      error: (e) => {
        console.error(e);
      }
    });
    
    this._wineService.getFeatured().subscribe({
      next: (response) => {
        console.log(response);
        this.featuredWines = response;
      }, 
      error: (e) => {
        console.error(e);
      }
    });
  }

  onKeyDownEvent($event: Event) {
    this.router.navigate(['/search-results']);
  }

  get searchService(): SearchService {
    return this._searchService;
  }
}
