@for(wine of wineList; track wine.id) {
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12" [routerLink]="'/wine/' + wine.id">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="wineCardContainer">
                        <img style="width: 100px; height: 240px" src="{{ wine.imagePath }}" />
                        <div class="wineCardDetails">
                            <div class="colorContainer">
                                <div [style.background]="wine.color" class="colorIcon"></div>
                                <span class="colorLabel">{{ wine.varietal }}</span>
                            </div>
                            <div class="wineCardMain">
                                <span class="wineCardVineyard">{{ wine.vinyard }}</span>
                                <span class="wineCardName">{{ wine.name }}</span>
                            </div>
                            <div class="wineCardLocation">{{ wine.subregion }}, {{ wine.region }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
@empty {
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
            No Featured Wines
        </div>
    </div>
}