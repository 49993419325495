import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { HeaderOptions } from '../header/header.component';

@Component({
  standalone: true,
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule
  ]
})
export class RegisterComponent {
  user: any = {};
  passwordConfirm: string = "";
  error: string = "";
  showPasswordType: boolean = false;
  showConfirmPasswordType: boolean = false

  constructor(private authService: AuthService, private router: Router, private _sharedService: SharedService) { }

  register() {
    this.error = "";
    if (this.user.password.length < 8) {
      this.error = "Password must be at least 8 characters";
    } else if (this.user.password != this.passwordConfirm) {
      this.error = "Passwords don't match";
    } else {
      this.authService.register(this.user).subscribe(() => {
        this.router.navigate(['/login']);
      }, error => {
        console.error(error);
        this.error = error.error;
      });
    }
  }
  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }
  ngOnInit(): void {
    this._sharedService.setData({ title: "Create Your Account", body: 'Already have an account? <a style="color:white;" href="/login">Sign in</a>', image: "header4.png" });
  }
}