<div class="mainContent">
    <app-wine-count-filter></app-wine-count-filter>
    <div class="card-item search-container">
        <img src="/assets/search.svg" class="search-svg" />
        <input type="text" placeholder="Filter Wine..." class="form-control" id="filter" (keyup)="filterService.filterWine();" [(ngModel)]="filterService.filterText">
    </div>
    <div class="card-item" [style]="filterService.onlyInstock ? 'background:#f4f4f5':''" (click)="filterService.onlyInstock=!filterService.onlyInstock;filterService.filterWine();">
        <img src="/assets/stores.svg" class="card-item-icon" />
        <span>Highlight {{filterService.getInStorageCount()}} Bottles in Storage Unit</span>
    </div>
    <app-sorted-wine-list></app-sorted-wine-list>
</div>