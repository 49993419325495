import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ActivityService } from '../../services/activity.service';
import { getSlotText, getTimezone } from '../../utils';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HeaderOptions } from '../header/header.component';

@Component({
  selector: 'app-activity-detail',
  standalone: true,
  imports: [RouterModule,CommonModule,ReactiveFormsModule, FormsModule],
  templateUrl: './activity-detail.component.html',
  styleUrl: './activity-detail.component.scss'
})
export class ActivityDetailComponent {
  constructor(private route: ActivatedRoute,private _sharedService: SharedService,private _activityService: ActivityService){}

  public slot : any = {};
  public storageLocation : any = {};
  public wine: any = {};
  public events: any[] = [];
  public eventTimeMap: any = {};

  ngOnInit(): void {
    let nodeId = this.route.snapshot.paramMap.get("nodeId") ?? "";
    this._activityService.getActivityDetail(nodeId,getTimezone()).subscribe({
      next: (response) => {
        this.slot = response.slot;
        this.storageLocation = response.storageLocation;
        this.wine = response.wine;
        this.events = response.events;
        this.eventTimeMap = response.timeMap;
      }, 
      error: (e) => {
        console.error(e);
      }
    });
    this._sharedService.setData({ body: "", title: "Recent Activity", image: "", backFunction: true });
  }
  getSlot(){
    return getSlotText(this.slot);
  }
}
