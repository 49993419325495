import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-generic-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './generic-modal.component.html',
  styleUrl: './generic-modal.component.scss'
})
export class GenericModalComponent {
  constructor(private modalService: ModalService ){}
  @Input() modalId:any = 'genericModal';
  @Input() modalTitle: any = '';
  @Input() modalBody: any = '';
  @Input() yesButtonText: any = 'Yes';
  @Input() noButtonText: any = 'No';
  @Input() displayNoButton: boolean = true;

  yesClick(){
    this.modalService.setYesClicked(this.modalId);
  }
}
