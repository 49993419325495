import { Component, Input } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule } from '@angular/forms';
import { numberToLetter } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-wine-grid-box',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule],
  templateUrl: './wine-grid-box.component.html',
  styleUrl: './wine-grid-box.component.scss'
})
export class WineGridBoxComponent {
  constructor(private route: ActivatedRoute,private fb: FormBuilder,private _sharedService: SharedService){}
  @Input() storageLocation: any = {};
  @Input() resetSub!: Subject<void>;
  selectedDepth:number = 1;

  ngOnInit(){
    this.resetSub.subscribe(()=>{
      this.selectedDepth = 1;
    });
  }

  getColumnNumber(column:number){
    return numberToLetter(column);
  }

  getNumberArray(numb:number){
    return Array.from({ length: numb }, (_, i) => i + 1)
  }

  increaseDepth(){
    if(this.selectedDepth < this.storageLocation.depth){
      this.selectedDepth++;
    }
  }

  decreaseDepth(){
    if(this.selectedDepth > 1){
      this.selectedDepth--;
    }
  }
}
