
<div class="mainContent">
  <div class="card-item">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <p class="heading-text">
            {{wine.name}}
          <p>
          <p class="subheading-text">
            {{wine.region}}
          </p>
          <div>
            <div class="wine-color">
              <div [style.background]="wine.color" class="color-icon"></div>
              <span class="color-label">{{ wine.varietal }}</span>
            </div>
          </div>
        </div>
        <div class="col-6" style="display: flex; justify-content: center; align-items: center;">
          <img class="wine-image" src="{{ wine.imagePath }}" />
        </div>
      </div>
        <div class="row">
          <div class="col-6">
            <div class="buttonContainer">
              <div class="buttonIcon">
                <img src ="assets/heart.svg"/>
              </div>
              <div *ngIf="!isFavorited" >
                <button class="btn wineDetailCardButton" (click)="addToFavorites(wine.id)">
                  Add to Favorites
                </button>
              </div>
              <div *ngIf="isFavorited">
                <button class="btn wineDetailCardButton" (click)="removeFromFavorites(wine.id)">
                  Remove from Favorites
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="buttonContainer">
              <div class="buttonIcon">
                <img src ="assets/bottle-small.svg"/>
              </div>
              <div *ngIf="(detailService.getInStorageCount() != 0)" class="div wineDetailCardButton">
                {{detailService.getInStorageCount()}} Bottles in Your Collection
              </div>
              <div *ngIf="(detailService.getInStorageCount() == 0) && !isWishlisted">
                <button class="btn wineDetailCardButton"  (click)="updateWishList(wine.id, true)">
                  Add to Wishlist
                </button>
              </div>
              <div *ngIf="((detailService.getInStorageCount() == 0) && isWishlisted)">
                <button class="btn wineDetailCardButton"  (click)="updateWishList(wine.id, false)">
                  Remove from Wishlist
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn cc-btn" style="width: 100%">Consume</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn adddToCollectionButton" style="width: 100%">Add to Collection</button>
          </div>
        </div>
    </div>
  </div>
  <div class="div spacing"></div>
    <div class="div spacing"></div>
    <div class="wineListContainer">
      <div class="wineListTextContainer">
        <div class="wineListText">{{detailService.getInStorageCount()}} Vintage in your collection</div>
      </div>
      <app-wine-detail-list></app-wine-detail-list>
    </div>
    <div class="detailsBoxContainer">
      <div class="detailCategoryContainer">
        <div class="detailTextCat">Type</div>
        <div class="detailText">
          {{ wine.type }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Producer</div>
        <div class="detailText">
          {{ wine.producer.name }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Variety</div>
        <div class="detailText">
          {{ wine.variety }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Designation</div>
        <div class="detailText">
          {{ wine.designation }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Vineyard</div>
        <div class="detailText">
          {{ wine.vineyard }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Country</div>
        <div class="detailText">
          {{ wine.country }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Region</div>
        <div class="detailText">
          {{ wine.region }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Sub Region</div>
        <div class="detailText">
          {{ wine.subRegion }}
        </div>
      </div>
      <div class="dividing-row"></div>
      <div class="detailCategoryContainer">
        <div class="detailTextCategory">Area</div>
        <div class="detailText">
          {{ wine.area }}
        </div>
      </div>
      <div class="dividing-row"></div>
    </div>
  </div>

