<div class="mainContent">
    <form (ngSubmit)="updateProfile()">
        <span class="error text-danger">{{error}}</span>
        <div *ngIf="alertMessage != ''">
            <div class="alert alert-success alert-bar" role="alert">
            <div>
                {{alertMessage}}
            </div>
            <span class="close-alert" (click)="alertMessage = ''">X</span>
            </div>
        </div>
        <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" [(ngModel)]="user.firstName" name="firstName">
        </div>
        <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" [(ngModel)]="user.lastName" name="lastName">
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" [(ngModel)]="user.email" name="email">
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input [type]="(showPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="password" [(ngModel)]="user.password" name="password">
            <img [src]="'/assets/' + (showPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showPasswordType")' class="eye-svg" />
        </div>
        <div class="form-group">
            <label for="password">Confirm Password</label>
            <input [type]="(showConfirmPasswordType ? 'text':'password')" placeholder="At least 8 characters"  class="form-control" id="passwordConfirm" [(ngModel)]="passwordConfirm" name="passwordConfirm">
            <img [src]="'/assets/' + (showConfirmPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showConfirmPasswordType")' class="eye-svg" />
        </div>
        <div class="form-group">
            <button type="submit" class="btn cc-btn col-12 mt-3">Update Profile</button>
        </div>
    </form>
</div>