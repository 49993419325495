import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const routeTransition = trigger('routeTransition', [
    transition('* => *', [
        style({
          position: 'relative'
        }),
        query(':enter, :leave', [
          style({
            //height: '100vh',
            left: 0,
            overflowY: 'visible',
            position: 'absolute',
            width: '100%',
            top:'{{topLeaving}}px',
          })
        ], { optional: true }),
        query(':enter', [
          style({
            top:'{{topComing}}px',
            transform: 'translateX(100%)'
          })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
          query(':leave', [
            animate('{{speed}} ease-out', style({
              transform: 'translateX(-100%)',
              top:'{{topLeaving}}px',
            }))
          ], { optional: true }),
          query(':enter', [
            animate('{{speed}} ease-out', style({
              transform: 'translateX(0%)'
            }))
          ], { optional: true })
        ]),
        query(':enter', animateChild(), { optional: true })
    ])
]);