import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private baseUrl = 'storage';

  constructor(private http: HttpClient) { }
  
  getBrowseList(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-list`,{});
  }
  exportWineList(unitId:number){
    return this.http.get(`${environment.apiBase}${this.baseUrl}/export-list`,{params:{unitId}});
  }
  removeUnit(unitId:number){
    return this.http.post(`${environment.apiBase}${this.baseUrl}/remove`,unitId);
  }
  getBrowseNonStorageUnitList() {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-non-storage-unit-list`);
  }
  postNewLocation(location: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/add-new-location`,location);
  }
}
