import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { formatWineVolume } from '../../utils';
import { ScrollService } from '../../services/scroll.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-activity-list',
  standalone: true,
  imports: [RouterModule,CommonModule],
  templateUrl: './activity-list.component.html',
  styleUrl: './activity-list.component.scss'
})
export class ActivityListComponent {
  constructor(private _scrollService: ScrollService) { }

  @Input() public activityList : any[] = [];
  @Input() public displayTimeAgo: boolean = true;

  ngOnInit(): void {
  }

  get lazyActivityList(){
    return this.activityList.slice(0,this._scrollService.maxDisplayVal);
  }

  getVolumeString(volume: number){
    return formatWineVolume(volume);
  }

  //converts the number of minutes ago into a string listing days/hours/mins since the most recent event
  getTimeAgo(minutes:number){
    let output = '';
    let days = (60 * 24);
    let hours = 60;
    if(minutes >= days){
      output += Math.floor(minutes / days).toString() + " days ";
      minutes = minutes % days;
    }
    if(minutes >= hours){
      output += Math.floor(minutes / hours).toString() + " hours ";
      minutes = minutes % hours;
    }
    if(minutes != 0){
      output += minutes.toString() + " minutes ";
    }
    output += "ago";
    return output;
  }
}
