<div class="mainContent">
    <div *ngIf="alertMessage != ''">
        <div [class]="'alert alert-bar ' + (alertError ? 'alert-danger' : 'alert-success' )" role="alert">
            <div>
            {{alertMessage}}
            </div>
            <span class="close-alert" (click)="alertMessage = ''">X</span>
        </div>
    </div>
    @for(storage of storageList; track storage.location.id){
        <div class="card">
            <div class="storage-header">
                <div>
                    <div class="location-name">{{storage.location.name}}</div>
                    <div class="gray">{{storage.location.description}}</div>
                </div>
                <div>
                    <div>
                        <a
                            href="#"
                            class="manage-button"
                            id="dropdownMenuButton-{{storage.location.id}}"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="selectedUnit = storage.location.id"
                        >
                            <img src="/assets/three-dots.svg"/>
                        </a>
                        <div class="dropdown-menu manage-options">
                            <button class="dropdown-item">Manage Storage Unit</button>
                            <div class="dividing-row low-margin"></div>
                            <button class="dropdown-item">Manage Bud Type</button>
                            <div class="dividing-row low-margin"></div>
                            <button class="dropdown-item">Manage Sensors</button>
                            <div class="dividing-row low-margin"></div>
                            <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#exportWinesModal">Export Wine List</button>
                            <div class="dividing-row low-margin"></div>
                            <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#removeUnitModal">Remove Unit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dividing-row"></div>
            <div class="storage-stats">
                <div>
                    <div class="stats-info">{{storage.slotCount}}</div>
                    <div class="gray stats-desc">Bottles</div>
                </div>
                <div>
                    <div class="stats-info">{{storage.inventoryCount}}</div>
                    <div class="gray stats-desc">Available</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgLastTemp)}}°</div>
                    <div class="gray stats-desc">Temperature</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgHumidity)}}%</div>
                    <div class="gray stats-desc">Humidity</div>
                </div>
            </div>
        </div>
    }
    <button type="button" class="btn cc-btn col-12 mt-3" routerLink="/storage-create">Add Storage Unit</button>
    <button type="button" class="btn secondary-btn col-12 mt-3" routerLink="/location-list"> Manage Locations</button>
</div>
<app-generic-modal 
    modalId="exportWinesModal"
    modalTitle="Create a Wine List from Storage Unit?"
    modalBody="Click the button below to have a wine list export for this storage unit created for you. We will email your email address you signed up with an easy link to download your wine list."
    yesButtonText="Create and Send My Export"
    [displayNoButton]="false"
/>
<app-generic-modal 
    modalId="removeUnitModal"
    modalTitle="Remove this Storage Unit?"
    modalBody="Are you sure you want to remove this storage unit?"
    yesButtonText="Remove Unit"
    noButtonText="Cancel"
    [displayNoButton]="true"
/>