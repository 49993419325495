<div class="mainContent">
  <div class="searchContainer">
    <div class="input-group mb-3 input-group-lg searchBar">
      <div class="input-group-prepend">
        <button class="btn btn-lg" type="button">
          <img src="/assets/search.svg" class="search-svg" />
        </button>
      </div>
      <input (focus)="onKeyDownEvent($event)" name="wineName" [(ngModel)]="wineName"
        type="text"
        class="form-control"
        placeholder="Type the name of a wine..."
        aria-label="search"
      />
    </div>
    <div class="search-padding"></div>
  </div>
  <div class="mainNav">
    <div class="mainNavItems">
      <div class="mainNavItem" routerLink="/scan-barcode">
        <div class="mainNavIcon">
          <img src="/assets/barcode.svg" />
        </div>
        <div class="mainNavLabel">Scan Barcode</div>
      </div>
      <div class="mainNavItem" routerLink="/search-label">
        <div class="mainNavIcon">
          <img src="/assets/label.svg" />
        </div>
        <div class="mainNavLabel">Search Label</div>
      </div>
      <div class="mainNavItem" routerLink="/browse-wine">
        <div class="mainNavIcon">
          <img src="/assets/browse.svg" />
        </div>
        <div class="mainNavLabel">Browse Wine</div>
      </div>
    </div>
  </div>
  <h2>Explore Your {{ winecount | number }} Wines</h2>
  <div class="row">
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine-list/favorite/Favorites/false">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/favorites.svg" />
        </div>
        <div class="subNavLabel">Favorites</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine-list/favorite/Wishlist/true">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/wishlist.svg" />
        </div>
        <div class="subNavLabel">Wishlist</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/varietals">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/varietals.svg" />
        </div>
        <div class="subNavLabel">Varietals</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/regions">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/regions.svg" />
        </div>
        <div class="subNavLabel">Regions</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/producers">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/producers.svg" />
        </div>
        <div class="subNavLabel">Producers</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/vintage">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/vintage.svg" />
        </div>
        <div class="subNavLabel">Vintage</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/purchased-locations">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/stores.svg" />
        </div>
        <div class="subNavLabel">Stores</div>
      </div>
    </div>
    <div class="col-md-3 col-6 gy-2" routerLink="/browse-wine/consumed-locations">
      <div class="subNavItem">
        <div class="subNavIcon">
          <img src="/assets/places.svg" />
        </div>
        <div class="subNavLabel">Places</div>
      </div>
    </div>
  </div>
  
  <h2>Recent Activity</h2>
  <app-activity-list [activityList]="recentActivity" [displayTimeAgo]="false" />

  <h2 class="mb-3">Featured Wines</h2>
  <app-wine-list [wineList]="featuredWines" displaySize="large"></app-wine-list>
</div>
