<div id="appHeaderElem">
  <ng-container *ngIf="data.type == enums.loggedOutWithImage">
    <div
      class="headerHeroImage"
      [style.background-image]="
        'linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),url(/assets/' +
        data.image +
        ')'
      "
    ></div>
    <div class="headerContent">
      <img src="/assets/logo.png" />
      <div *ifChanges="data.title" [@myTrigger]>
        <h1 class="headerTitle">{{ data.title }}</h1>
        <div class="headerText" [innerHTML]="data.body"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data.type == enums.loggedInWithImage">
    <div
      class="headerHeroImageSmall"
      [style.background-image]="
        'linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),url(/assets/' +
        data.image +
        ')'
      "
    ></div>
    <div class="loggedin-presize">
      <div class="headerContentSmall">
        <img src="/assets/logo_small.svg" class="left" />
        <div class="headerTextSmall" [innerHTML]="data.body"></div>
        <app-profile-header-options [blackIcon]="false"/>
      </div>
      <div *ifChanges="data.title" [@myTrigger]>
        <h1 class="headerTitle">{{ data.title }}</h1>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data.type == enums.noImageSmallBack">
    <div class="headerContentSmall no-image">
      <div class="back-button" (click)="goBackFunction()">
        <img src='/assets/back.svg' />
      </div>
      <div *ifChanges="data.title" [@myTrigger]>
        <div class="headerTitle">{{ data.title }}</div>
      </div>
      <app-profile-header-options [blackIcon]="true"/>
    </div>
  </ng-container>
  <ng-container *ngIf="data.type == enums.noImageSmallWineIcon">
    <div class="headerContentSmall no-image">
      <div>
        <img src='/assets/logo_small.svg' />
      </div>
      <div *ifChanges="data.title" [@myTrigger]>
        <div class="headerTitle">{{ data.title }}</div>
      </div>
      <app-profile-header-options [blackIcon]="true"/>
    </div>
  </ng-container>
  <ng-container *ngIf="data.type == enums.wineDetailWithImage">
    <div
      class="wineDetailsImage"
      [style.background]="
        'linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),url(/assets/' +
        data.image +
        ')'
      "
    ></div>
    <div class="headerContentWineDetails">
      <div class="back-button" (click)="goBackFunction()">
        <img src='/assets/back2.svg' />
      </div>
      <div class="headerTextSmall" [innerHTML]="data.body"></div>
      <app-profile-header-options [blackIcon]="false"/>
    </div>
    <div *ifChanges="data.title" [@myTrigger]>
      <h1 class="headerTitle">{{ data.title }}</h1>
    </div>
  </ng-container>  
</div>
