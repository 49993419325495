import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = 'users';

  constructor(private http: HttpClient) { }

  getUser(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/me`);
  }
  getById(id:string): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/by-id`,{params: {id}});
  }
  update(user: any): Observable<any> {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/update`, user);
  }
  
  getAccountUsers(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/account-users`);
  }
}
