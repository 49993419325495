import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private baseUrl = 'activity';

  constructor(private http: HttpClient) { }

  getActivity(maxNumb:number): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/list`,{params: {maxNumb}});
  }
  getActivityDetail(nodeId:string,timezone:string): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/detail`,{params: {nodeId,timezone}});
  }
}
