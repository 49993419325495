<div class="mainContent">
    @for(cat of links; track $index) {
        <div [class]="'card-item ' + (cat.disabled ? 'disabled' : '')" [routerLink]="cat.link">
            <div class="card-item-icon">
                <img [src]="cat.icon" />
            </div>
            <div class="card-item-label">
                {{cat.text}}
            </div>
        </div>
    }
</div>
