<div class="mainContent">
    <div *ngIf="category == ''">
        <div class="sub-header">
            Select Category
        </div>
        @for(cat of categories; track $index) {
            <div [class]="'card-item ' + (cat.disabled ? 'disabled' : '')" [routerLink]="'/browse-wine' + cat.link">
                <div class="card-item-icon">
                    <img [src]="cat.icon" />
                </div>
                <div class="card-item-label">
                    {{cat.text}}
                </div>
            </div>
        }
    </div>
    <div *ngIf="category != ''">
        <div class="header-back" (click)="goBack()">
            <div class="small-back">
                <img src='/assets/back.svg' />
            </div>
            <div class="sub-header capitalized">
                <span *ngIf="selectionText != ''">
                    All
                </span>
                {{getHeaderText()}}
            </div>
        </div>
        <div *ngIf="categoryKey != '' || (category != '' && category != 'varietals')">
            <div class="card-item search-container">
                <img src="/assets/search.svg" class="search-svg" />
                <input type="text" placeholder="Filter Options..." class="form-control" id="filter" [(ngModel)]="filterText">
            </div>
        </div>
        @for(key of getSearchKeys(); track $index) {
            <div class="card-item" [routerLink]="getSubCatLink(key,searchResults[key])">
                <div class="card-item-label">
                    {{searchResults[key]}}
                    <div class="small-forward">
                        <img src='/assets/back.svg' />
                    </div>
                </div>
            </div>
        }
    </div>
</div>