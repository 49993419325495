<div>
    @for(wrapperKey of filterService.getWineKeys(); track $index) {
        <div *ngIf="!searchResults" class="card-item gray-card">
            {{wrapperKey}}
        </div>
        <div *ngIf="searchResults" class="card-item gray-card">
            {{filterService.wineList.length}} Wines
        </div>
        @for(wine of filterService.filteredWineList[wrapperKey]; track wine.id) {
            <div *ngIf="!$first" class="dividing-row"></div>
            <div class="card-item wine-card" [routerLink]="'/wine/' + wine.id">
                <img class="wine-image" src="{{ wine.imagePath }}" />
                <div class="card-item-middle">
                    <div>
                        <div class="card-item-label wine-card-item-label">
                            {{wine.name}}
                        </div>
                        <div class="gray">
                            {{wine.area}}
                        </div>
                    </div>
                    <div class="wine-color">
                        <div [style.background]="wine.color" class="color-icon"></div>
                        <span class="color-label">{{ wine.varietal }}</span>
                    </div>
                </div>
                <div class="card-item-in-storage" *ngIf="filterService.getWineCount(wine.id) != 0">
                    <div class="notificationBottleCountContainer">
                        <div class="bottleContainer">
                            <div bottleIcon="bottleIcon">
                                <img src="/assets/bottle-small.svg" />
                            </div>
                        </div>
                        <span class="bottleCount">{{filterService.getWineCount(wine.id)}}</span>
                    </div>
                    <div class="volume">({{ filterService.getVolumeString(wine.volume)}})</div>
                </div>
            </div>
        }
    }
</div>
