
<div>
    @for(wine of wineDetailService.refreshWineList; track wine.id) {
        <div *ngIf="!$first" class="dividing-row"></div>
        <div class="card-item wine-card" >
            <img class="wine-image" src="{{ wine.imagePath }}" />
            <div class="card-item-middle">
                <div>
                    <div class="card-item-label wine-card-item-label">
                        {{wine.name}}
                    </div>
                    <div class="gray">
                        {{wine.storageName}}
                    </div>
                </div>
            </div>
            <div class="card-item-in-storage" *ngIf="wineDetailService.getWineCount(wine.id) != 0">
                <div class="notificationBottleCountContainer">
                    <div class="bottleContainer">
                        <div bottleIcon="bottleIcon">
                            <img src="{{ wine.imagePath }}" />
                        </div>
                    </div>
                    <span class="bottleCount">{{wineDetailService.getWineCount(wine.id)}}</span>
                </div>
                <div class="volume">({{ wineDetailService.getVolumeString(wine.volume)}})</div>
            </div>
        </div>
        <div class="card-item">
            <p class="wineStorageLocationText">
                Located in Slot {{slotToLetter(wine.positionX, wine.positionY)}}
                in the
            </p>
            <p class="wineStorageLocationText underline">
                {{wine.storageName}}
            </p>
        </div>
    }
</div>
