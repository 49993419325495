import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule,ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import 'bootstrap/dist/js/bootstrap.bundle';
import { HeaderOptions } from '../header/header.component';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule
  ]
})
export class LoginComponent {
  credentials: any = {};
  error: string = "";
  loaded: boolean = false;
  showPasswordType: boolean = false;
  alertMessage: string = "";
  constructor(private authService: AuthService,private route: ActivatedRoute, private router: Router, private _sharedService: SharedService) { }

  login() {
    this.error = "";
    this.authService.login(this.credentials).subscribe({
      next: (response) => {
        console.log(response);
        var expires = new Date();
        expires.setHours(expires.getHours() + 1);
        localStorage.setItem('access_token', response.token);
        localStorage.setItem('access_token_expiration', expires.getTime().toString());
        localStorage.setItem('incomplete',response.incomplete);
        if(response.incomplete){
          this.router.navigate(['/complete-profile']);
        }
        else{
          this.router.navigate(['/home']);  
        }
      }, 
      error: () => {
        this.error = "Invalid Email/Password";
      }
    });

  }
  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }
  ngOnInit(): void {
    this.loaded = true;
    this.route.queryParams.pipe().subscribe({
      next: (param) =>{
        if(param["msg"] != null){
          this.alertMessage = param["msg"];
        }
      }
    });
    this._sharedService.setData({ title: "Welcome Back", body: 'Don\'t have an account? <a style="color:white;" href="/register">Sign up</a>', image: "header1.png"});
  }
}