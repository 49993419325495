import { Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-scan-barcode',
  standalone: true,
  imports: [],
  templateUrl: './scan-barcode.component.html',
  styleUrl: './scan-barcode.component.scss'
})
export class ScanBarcodeComponent {
  constructor(private _sharedService: SharedService){}
  ngOnInit(): void {
    this._sharedService.setData({  body: "", title: "Scan Barcode", image: "", backFunction: true });
  }
}
