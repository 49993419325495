<div class="mainContent">
  <div class="searchContainer">
    <div class="input-group mb-3 input-group-lg searchBar">
      <div class="input-group-prepend">
        <button class="btn btn-lg" type="button">
          <img src="/assets/bottle-small.svg" />
        </button>
      </div>
      <input
        #input
        name="input"
        id="input"
        [(ngModel)]="searchService.query"
        (keydown)="onKeyDown($event)"
        type="text"
        class="form-control"
        placeholder="{{ searchService.query }}"
        aria-label="search"
      />
    </div>
  </div>
  <div *ngIf="!displayProducerResults" class="result-list-container container">
    <div *ngFor="let result of searchResults | keyvalue">
      <div class="result-card-content clickable">
        <img
        *ngIf="!this.searchResults[searchService.getResultKey(result.key)].wine"
          src="/assets/search-producer.svg"
          class="icon"
        />
        <img
        *ngIf="this.searchResults[searchService.getResultKey(result.key)].wine"
          src="/assets/bottle-small.svg"
          class="icon"
        />
        {{ this.searchResults[searchService.getResultKey(result.key)].name}}
      </div>
      <div class="item-divider"></div>
    </div>
  </div>
  <div *ngIf="displayProducerResults">
    <div>{{searchService.sortedProducerList.length}} Producers</div>
    @for(producer of searchService.sortedProducerList; track $index) {
      <div class="container">
        <div class="card" style="padding-bottom: 16px;">
          <div class="row">
            <div class="col-3">
              <img class="producer-image" src="{{ producer.imagePath }}" />
            </div>
            <div class="col-9">
              <p class="heading-text">
                {{producer.name}}
              <p>
              <p class="subheading-text">
                {{producer.region}}
              </p>
            </div>
          </div>
        </div>
      </div>
      } 
    <app-sorted-wine-list [searchResults]="true"> </app-sorted-wine-list>
  </div>
</div>
