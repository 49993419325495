import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CanActivateFn } from '@angular/router';

export const basicGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if(authService.isIncomplete()){
    router.navigate(['/complete-profile']);
    return false;
  }
  else if (authService.isLoggedIn()) {
    return true;
  } 
  else {
    router.navigate(['/login']);
    return false;
  }
};
