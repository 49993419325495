import { Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-search-label',
  standalone: true,
  imports: [],
  templateUrl: './search-label.component.html',
  styleUrl: './search-label.component.scss'
})
export class SearchLabelComponent {
  constructor(private _sharedService: SharedService){}
  ngOnInit(): void {
    this._sharedService.setData({  body: "", title: "Search Label", image: "", backFunction: true });
  }
}
