<div class="mainContent">
    <form>
        <div class="form-group">
            <label for="name">Rack Name</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="storageLocation.name" name="name">
        </div>
        <div class="form-group">
            <label for="description">Description of Storage Unit</label>
            <input type="text" class="form-control" id="description" [(ngModel)]="storageLocation.description" name="description">
        </div>
        <div class="form-group">
            <label for="location">Location</label>
            <select id="location" class="form-select">
                <option value ="1">Location 1</option>
                <option value ="2">Location 2</option>
                <option value ="3">Location 3</option>
            </select>
        </div>
        <div class="form-check form-switch">
            <input id="customType" type="checkbox" role="switch" class="form-check-input" [(ngModel)]="customType" name="customType">
            <label for="customType" class="form-check-label">Setup as a custom storage system</label>
        </div>
        <div *ngIf="!customType" class="form-group">
            <label for="vendor">Wine Storage Vendor</label>
            <select id="vendor" class="form-select">
                <option value ="1">Vendor 1</option>
                <option value ="2">Vendor 2</option>
                <option value ="3">Vendor 3</option>
            </select>
        </div>
        <div class="form-group">
            <label for="category">Category</label>
            <select id="category" class="form-select">
                <option value="1">Category 1</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
            </select>
        </div>
        <div *ngIf="!customType" class="form-group">
            <label for="storageType">Wine Storage Type</label>
            <select id="storageType" class="form-select">
                <option value ="1">Wine Storage Type 1</option>
                <option value ="2">Wine Storage Type 2</option>
                <option value ="3">Wine Storage Type 3</option>
            </select>
        </div>
        <div *ngIf="customType" class="form-group">
            <label for="rackLayout">Rack Layout</label>
            <div class="radio-btn-container">
                <div class="radio-btn">
                    <button type="button" (click)="storageLocation.stacked=true" >
                        <img src="/assets/stacked.svg" />   
                        Stacked
                    </button>
                </div>
                <div class="radio-btn">
                    <button type="button" (click)="storageLocation.stacked=false;validateMin('columns');validateMin('rows');" >
                        <img src="/assets/staggered.svg" />
                        Staggered
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="customType" class="custom-position-layout">
            <label for="columns">Columns</label>
            <label for="rows">Rows</label>
            <label for="depth">Depth</label>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('columns')"><img src="/assets/minus.svg"/></button>
                <input type="number" id="columns" (keyup)="validateMin('columns')" name="columns" [(ngModel)]="storageLocation.columns" />
                <button type="button" (click)="incrementNumber('columns')"><img src="/assets/plus.svg"/></button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('rows')"><img src="/assets/minus.svg"/></button>
                <input type="number" id="rows" name="rows" (keyup)="validateMin('rows')" [(ngModel)]="storageLocation.rows"/>
                <button type="button" (click)="incrementNumber('rows')"><img src="/assets/plus.svg"/></button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('depth');resetSelectedDepth();"><img src="/assets/minus.svg"/></button>
                <input type="number" id="depth" name="depth" (keyup)="validateMin('depth');resetSelectedDepth();" [(ngModel)]="storageLocation.depth" />
                <button type="button" (click)="incrementNumber('depth');resetSelectedDepth();"><img src="/assets/plus.svg"/></button>
            </div>
        </div>
        <app-wine-grid-box [storageLocation]="storageLocation" [resetSub]="resetSelectedDepthSub"/>
        <div>
            <button class="btn cc-btn save-button">Save Storage Unit</button>
        </div>
    </form>
</div>