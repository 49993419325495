import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { HeaderOptions } from '../header/header.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  credentials: any = {};
  error: string = "";
  success: boolean = false;
  showPasswordType: boolean = false;
  showConfirmPasswordType: boolean = false
  constructor(private authService: AuthService,private route: ActivatedRoute,  private router: Router, private _sharedService: SharedService) { }

  resetPassword() {
    this.error = "";
    if(!(("password" in this.credentials) && ("confirmPassword" in this.credentials))){
      this.error = "Please enter a new password";
      return;
    }
    if(this.credentials.password !== this.credentials.confirmPassword){
      this.error = "Passwords do not match";
      return;
    }
    this.authService.resetPassword(this.credentials).subscribe({
      next: (response) => {
        
        if(response){
          this.success = true;
          this.router.navigate(['/login'], {queryParams: {'msg': "Your password has successfully been reset."}});
        }
        else{
          this.error = "Failed to reset the password";
        }
      }, 
      error: () => {
        this.error = "Failed to reset the password";
      }
    });

  }

  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }

  ngOnInit(): void {
    this._sharedService.setData({ title: "Reset Password", body: 'Use the form below to create a new password for your account. Please select a secure, unique password to keep your account protected.<br/>Remember your password? <a style="color:white;" href="/login">Sign in</a>', image: "header3.png"});
    console.log(this.route.queryParams);
    this.route.queryParams.pipe().subscribe({
      next: (param) =>{
        this.credentials.token = param["token"];
        this.credentials.email = param["email"];
      }
    });
  }
}
