<div class="mainContent">
    <div class="container">
        <form>
            <div class="form-group padding-big-bottom">
              <label for="inputLocationName" class="padding-small">Location Name</label>
              <input type="text" class="form-control" id="inputLocationName" name="input1" placeholder="Name" ngModel #input1="ngModel">
            </div>
            <div class="form-group padding-big-bottom">
              <label for="inputDescription" class="padding-small">Description of Location</label>
              <textarea class="form-control big-text-field " id="inputDescription" name="input2" placeholder="Example: Upstairs Wine Room" ngModel #input2="ngModel"></textarea>
            </div>
            <button (click)="handleSubmit(input1.value, input2.value)" type="submit" class="btn cc-btn big-button">Save Location</button>
          </form>
    </div>
</div>
