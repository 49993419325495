import { ActivatedRoute, Route, Router, RouterModule } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HeaderOptions } from "../header/header.component";
import { Component } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { UserService } from "../../services/user.service";
import { CommonModule, Location } from "@angular/common";
import { ScrollService } from '../../services/scroll.service';
import { WineService } from "../../services/wine.service";
import { FormsModule } from "@angular/forms";
import { WineDetailListComponent } from "../wine-detail-list/wine-detail-list.component";
import { WineDetailService } from "../../services/wine-detail.service";

@Component({
  selector: 'app-wine',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, WineDetailListComponent],
  templateUrl: './wine.component.html',
  styleUrl: './wine.component.scss'
})
export class WineComponent {
;
  constructor(private _scrollService: ScrollService, private authService: AuthService, private location: Location, private router: Router, private _sharedService: SharedService, private _userService: UserService, private route: ActivatedRoute, private _wineService: WineService, private _wineDetailService : WineDetailService) { }

  public user: any = {};
  public wineID: string = "";
  public wine: any = {};
  public userId: number = 0;
  public isWishlisted: boolean = false;
  public isFavorited: boolean = false;
  public zeroBottlesInCollection: boolean = true;

  ngOnInit() : void {
    this._sharedService.setData({ body: "Wine Detail", image: "header4.png", backFunction: true });
    this.wineID = this.route.snapshot.paramMap.get("id") ?? "";
    this.detailService.resetData();

    this._userService.getUser().subscribe({
      next: (response) => {
        this.userId = response.id;
        this.user = response;
      }, 
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getWineDetails((Number(this.wineID))).subscribe({
      next: (response) => {
        this.wine = response;
      }, 
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getSameWinNotConsumed(this.userId, Number(this.wineID)).subscribe({
      next:([res1]) =>{
      this._wineDetailService.wineList = new Array(res1);
      let preRes = new Array(res1);
      // We loop through every wine we have that is simialr to the one
      // on this page in order to set storage/slot properties
      // to display on the front end
      for (let i=0; i<preRes.length; i++) {
        this._wineService.getSlotAndStorageForWine(preRes[i].id).subscribe({next: (storageRes)=>{
            console.log(storageRes);
            if (storageRes.slot == null) {
              // These position properties
              // being set to -1 indicates
              // that there is an error
              // retrieving the slot
              // positioning
              preRes[i].positionX = -1;
              preRes[i].positionY =-1;
            } else {
              preRes[i].positionX = storageRes.slot.positionX;
              preRes[i].positionY = storageRes.slot.positionY;
            }
            preRes[i].storageName = storageRes.storageLocation.name;
         }});
      }
      this._wineDetailService.wineList = preRes;
      this.detailService.refreshList();
    },
    error:(error)=>{
      console.log(error);
    }});

    this._wineService.getWineCounts().subscribe({
      next: (response) => {
        this._wineDetailService.wineCounts = response;
      }, 
      error: (e) => {
        console.log(e);
      }
    });

     this._wineService.getWineWishListStatus(Number(this.wineID)).subscribe({
      next: (response) => {
        this.isWishlisted = response;
      }, 
      error: (e) => {
        this.isWishlisted = false;
      }
    });

    this._wineService.getFavoriteStatus(Number(this.wineID)).subscribe({
      next: (response) => {
        console.log(response);
        this.isFavorited = response;
      }, 
      error: (e) => {
        this.isFavorited = false;
      }
    });

    if (this._wineDetailService.wineCounts<=0) {
      // This being set to false will
      // repleace the "# bottles in colletion"
      // label with a wishlist button
      this.zeroBottlesInCollection=false;
    }
  }

  get detailService(){
    return this._wineDetailService;
  }

  get wineService() {
    return this._wineService;
  }

  removeFromFavorites(wineID: number) : void {
    this.wineService.postFavoriteRemoval(wineID).subscribe(response => {
      this.isWishlisted = false;
      this.isFavorited = false;
    });
  }

  addToFavorites(wineID: number) : void {
    this.wineService.postNewFavorite(wineID).subscribe(response => {
      this.isFavorited = true;
    });
  }

  updateWishList(wineID: number, add: boolean) : void {
    this.wineService.postWishListUpdate(wineID, add).subscribe(response => {
      this.isWishlisted = add;
      this.isFavorited = add;
    });
  }

}
