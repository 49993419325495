@for(row of lazyActivityList; track row.nodeId) {
    @let wine = row.wine;
    <div class="card-item wine-card" [routerLink]="'/activity-detail/' + row.nodeId">
        <img class="wine-image" src="{{ wine.imagePath }}" />
        <div class="card-item-middle">
            <div>
                <div class="card-item-label wine-card-item-label">
                    {{wine.name}}
                </div>
                <div class="gray">
                    {{wine.area}}
                </div>
            </div>
            <div class="wine-color">
                <div [style.background]="wine.color" class="color-icon"></div>
                <span class="color-label">{{ wine.varietal }}</span>
            </div>
        </div>
        <div class="card-item-in-storage">
            <div class="volume">({{ getVolumeString(wine.volume)}})</div>
        </div>
    </div>
    @if(displayTimeAgo){
        <div class="card-item gray-card">
            <div>
                {{row.eventCount}} events
            </div>
            <div>
                {{getTimeAgo(row.minuteDifference)}}
            </div>
        </div>
    }
    @else if(!$last) {
        <div class="dividing-row"></div>
    }
}
@empty {
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
            No Recent Activity
        </div>
    </div>
}