import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WineFilterService } from '../../services/wine-filter.service';

@Component({
  selector: 'app-sorted-wine-list',
  standalone: true,
  imports: [CommonModule,RouterModule, ReactiveFormsModule, FormsModule ],
  templateUrl: './sorted-wine-list.component.html',
  styleUrl: './sorted-wine-list.component.scss'
})
export class SortedWineListComponent {

  // Search Results Component sets this to
  // true in order for this component
  // display the wine count of all the
  // wines that were returned from the search
  // result
  @Input() searchResults: boolean = false;

  constructor(private _scrollService: ScrollService,private _wineFilterService: WineFilterService) { }
  private filterSub!: Subscription;

  ngOnInit(): void {
    this._scrollService.resetMax();
    this.filterSub = this._scrollService.refilter$.subscribe({
      next:()=>{
        this._wineFilterService.filterWine();
      }
    });
  }

  ngOnDestroy(){
    this._scrollService.resetMax();
    this.filterSub.unsubscribe();
  }

  get filterService(){
    return this._wineFilterService;
  }
}
