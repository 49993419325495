import { Component, Directive, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ChildrenOutletContexts, Router,RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import 'bootstrap/dist/js/bootstrap.bundle';
import { ProfileHeaderOptionsComponent } from "../profile-header-options/profile-header-options.component";

//enum for the different header types
export enum HeaderOptions{
  loggedOutWithImage,
  loggedInWithImage,
  noImageSmallBack,
  noImageSmallWineIcon,
  wineDetailWithImage
}

@Directive({
  selector: '[ifChanges]',
  standalone: true
})
export class IfChangesDirective {
  private currentValue: any;
  private hasView = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  @Input() set ifChanges(val: any) {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (val !== this.currentValue) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.currentValue = val;
    }
  }
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, IfChangesDirective, RouterModule, ProfileHeaderOptionsComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [
    trigger('myTrigger', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', [animate('0.3s 0.3s')]),
      transition('* => void', [animate('0.3s')])
    ]),
    trigger('titleTrigger', [
      state('void', style({ position: 'absolute', opacity: 0 })),
      state('*', style({ opacity: 1, position: 'relative' })),
      transition('void => *', [style({ position: 'absolute', transform: 'translateX(-100%)' }), animate(10000)]),
      transition('* => void', [animate(10000, style({ position: 'absolute', transform: 'translateX(100%)' }))]),
    ]),
    trigger('slideInOut', [
      state('*', style({
        'height': '*', 'opacity': '1', 'overflow': 'hidden', 'visibility': 'visible'
      })),
      state('void', style({
        'height': '0px', 'opacity': '0', 'overflow': 'hidden', 'visibility': 'hidden'
      })),
      transition('* => void', [group([
        animate('.5s ease-in-out', style({
          'opacity': '0'
        })),
        animate('.6s ease-in-out', style({
          'height': '0px'
        })),
        animate('.5s ease-in-out', style({
          'visibility': 'hidden'
        }))
      ]
      )]),
      transition('void => *', [group([
        animate('.5s ease-in-out', style({
          'visibility': 'visible'
        })),
        animate('.6s ease-in-out', style({
          'height': '*'
        })),
        animate('.5s ease-in-out', style({
          'opacity': '1'
        }))
      ]
      )])
    ])
  ]
})
export class HeaderComponent {
  data: any;
  public enums = HeaderOptions;

  constructor(private sharedService: SharedService, private router: Router,private location: Location,private contexts: ChildrenOutletContexts) { }

  ngOnInit(): void {
    this.sharedService.sharedData.subscribe(result => {
      this.data = result;
      this.data.type = this.contexts.getContext('primary')?.route?.snapshot?.data?.['header'];
    });
  }

  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    // If we don't have an anchor tag, we don't need to do anything.
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    // Prevent page from reloading
    event.preventDefault();
    let target = <HTMLAnchorElement>event.target;
    // Navigate to the path in the link
    this.router.navigate([target.pathname]);
  }
  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    this.router.navigate(['/login']);
  }

  goBackFunction(): void{
    if(!this.data.backFunction){
      this.router.navigate(['/home']);
    }
    else{
      this.goBack();
    }
  }

  goBack(){
    this.location.back();
  }
}
