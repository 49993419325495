import { Component } from '@angular/core';
import { WineFilterService } from '../../services/wine-filter.service';
import 'bootstrap/dist/js/bootstrap.bundle';

@Component({
  selector: 'app-wine-count-filter',
  standalone: true,
  imports: [],
  templateUrl: './wine-count-filter.component.html',
  styleUrl: './wine-count-filter.component.scss'
})
export class WineCountFilterComponent {

  constructor(private _wineFilterService: WineFilterService){}
  get filterService(){
    return this._wineFilterService;
  }
}
