//Used in the number to letter function
var letterString: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//General static helper functions that'll be used across multiple files
export function formatWineVolume(volume: number) {
  if (volume >= 1000) {
    return (volume / 1000) + 'L';
  }
  return volume + 'ml';
}

//Column is the letter, row is the number
export function getSlotText(slot: any) {
  if (slot.positionX == null || slot.positionY == null) {
    return '';
  }
  return numberToLetter(slot.positionX) + slot.positionY.toString();
}

//Converts a number to the multi A-Z letter based system 
export function numberToLetter(numb: number): string {
  //need a number greater than 0
  if (numb == null || numb < 1) {
    return '';
  }
  if (numb > 26) {
    return numberToLetter(Math.floor((numb - 1) / 26)) + letterString[((numb - 1) % 26)];
  }
  else {
    return letterString[((numb - 1) % 26)];
  }
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}