import { Injectable } from '@angular/core';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _scrollService: ScrollService) { }
  // list to display in type follow up queries
  public searchResults: any = [];
  // list that is used by sorted wine
  // list component to filter and display
  // each wine accordingly
  public sortedWineList: any = [];
  // list that is populated by wines
  // on the typsescript side
  public presortedWineList: any[] = [];
  // list that is populated by producers
  // on the typescript side
  public presortedProducerList: any[] = [];
  // this is a presorted list
  // that combines both wine and producer
  // objects
  public presortedCombinedList: any[] = [];
  // list that will be used to display
  // all producers above the sorted wine
  // component
  public sortedProducerList: any[] = [];
  public query: string = "";



  sortResultList() {

    this.presortedWineList.sort((a,b)=>a.name.localeCompare(b.name));
    this.presortedProducerList.sort((a,b)=>a.name.localeCompare(b.name));
    // we set a new attribute of wine so that the
    // html icon knows which icon to display
    // alongside each result item
    for (let wine of this.presortedWineList) {
      wine.wine=true;
    }
    for (let producer of this.presortedProducerList) {
      producer.wine=false;
    }
    this.sortedWineList = this.presortedWineList.slice();
    this.sortedProducerList = this.presortedProducerList;
    this.presortedCombinedList = [...this.presortedProducerList,...this.presortedWineList];
    this.presortedCombinedList.sort((a,b)=>a.name.localeCompare(b.name));
    this._scrollService.setMax(this.presortedCombinedList.length);
    this.searchResults = this.presortedCombinedList.slice(0, this._scrollService.maxDisplayVal);

  }

  set setQuery(name: string) {
    this.query = name;
  }

  resetData(){
    this.presortedWineList = [];
    this.presortedProducerList = [];
    this.presortedCombinedList = [];
    this.sortedWineList = [];
    this.sortedProducerList = [];
    this.searchResults = [];
  }

  // this is used in the html side
  // to convert the key of a results
  // object of unknown type to 
  // string so that we can index
  // the list of the combined
  // list that contains
  // both wines and producers
  getResultKey(key: unknown): any {
    return String(key);
  }
}
