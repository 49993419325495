import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { HeaderOptions } from '../header/header.component';
import { UserService } from '../../services/user.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './profile-edit.component.html',
  styleUrl: './profile-edit.component.scss'
})
export class ProfileEditComponent {
  user: any = {};
  isUser:boolean = false;
  passwordConfirm: string = "";
  error: string = "";
  showPasswordType: boolean = false;
  showConfirmPasswordType: boolean = false;
  alertMessage: string = '';
  mustComplete:boolean = false;

  constructor(private router: Router,private route: ActivatedRoute,private _sharedService: SharedService,private _userService: UserService) { }

  ngOnInit(): void {
    let userId = this.route.snapshot.paramMap.get("id") ?? "";
    let sub;
    if(userId != ""){
      sub = this._userService.getById(userId);
    }
    else{
      this.isUser =true;
      sub = this._userService.getUser();
    }
    this._sharedService.setData({ title: "Profile" });
    sub.subscribe({
      next: (response) => {
        this.user.firstName = response.firstName;
        this.user.lastName = response.lastName;
        this.user.email = response.email;
        this.user.id = response.id;
        this.mustComplete = response.finishInviteInfo;
      }, 
      error: (e) => {
        console.error(e);
      }
    });
  }

  updateProfile(){
    this.error = "";
    if(this.user.firstName == null ||this.user.firstName == '' 
        || this.user.lastName == null || this.user.lastName == ''){
      this.error = 'Please enter your name';
    }
    
    if(this.user.password != null && this.user.password != ''){
      if (this.user.password.length < 8) {
        this.error = "Password must be at least 8 characters";
      } else if (this.user.password != this.passwordConfirm) {
        this.error = "Passwords don't match";
      }
    }
    else if(this.mustComplete){
      this.error = "You must enter a new password in order to finish profile setup";
    }

    if (this.error == '') {
      this._userService.update(this.user).subscribe({next :()=> {
        if(this.mustComplete && this.isUser){
          localStorage.setItem('incomplete','false');
          this.router.navigate(['/home']);
        }
        this.alertMessage = 'Successfully updated the profile information';
      },
      error: (error) => {
        console.error(error);
        this.error = error.error.detail;
      }});
    }
  }

  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }
}
