<div class="wine-count-header">
    <span class="storage-count">
        {{filterService.filteredWineListPreDisplay.length}} Bottles
    </span>
    <div class="card-item filter-btn">
        <a
            href="#"
            id="filterDropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <img src="/assets/bars-filter.svg" class="card-item-icon" />
            {{filterService.sortTypeDisplay}}
        </a>
        <div class="dropdown-menu" aria-labelledby="filterDropdown">
            <button class="dropdown-item" (click)="filterService.sortTypeDisplay='A-Z';filterService.sortType='alpha';filterService.filterWine()">A-Z</button>
            <button class="dropdown-item" (click)="filterService.sortTypeDisplay='Vintage';filterService.sortType='vintage';filterService.filterWine()">Vintage</button>
            <button class="dropdown-item" (click)="filterService.sortTypeDisplay='Region';filterService.sortType='region';filterService.filterWine()">Region</button>
        </div>
    </div>
</div>