<div class="mainContent">
    <div>
        You can invite additional users to help manage your account.
        Invited users are shown below.
    </div>
    <div class="error text-danger">{{removeError}}</div>
    <div *ngIf="removeAlertMessage != ''" class="alert alert-success alert-bar" role="alert">
        <div>
        {{removeAlertMessage}}
        </div>
        <span class="close-alert" (click)="removeAlertMessage = ''">X</span>
    </div>
    @for(user of accountUsers; track user.id){
        <div class="card-item">
            <div class="card-item-icon" [routerLink]="'/profile-edit/' + user.id">
                <img src="/assets/profile.svg" />
            </div>
            <div [routerLink]="'/profile-edit/' + user.id">
                <div>
                    {{user.firstName}} {{user.lastName}}
                </div>
                <div>
                    {{user.email}}
                </div>
            </div>
            <button class="remove-user" (click)="removeUser(user)" data-bs-toggle="modal" data-bs-target="#deleteModal">
                <img src="/assets/close.svg" />
            </button>
        </div>
    }
    <div>
        <div class="invite-header">
            Invite a New User
        </div>
        <span class="error text-danger">{{error}}</span>
        <div *ngIf="alertMessage != ''" class="alert alert-success alert-bar" role="alert">
            <div>
              {{alertMessage}}
            </div>
            <span class="close-alert" (click)="alertMessage = ''">X</span>
        </div>
        <form (ngSubmit)="addUser()">
        <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" [(ngModel)]="inviteEmail" name="email">
            </div>
            <button type="submit" class="btn cc-btn col-12 mt-3">Invite User</button>
        </form>
    </div>
</div>
<app-generic-modal 
    modalId="deleteModal"
    modalTitle="Delete Account?"
    modalBody="Are you sure you want to delete this account?"
    yesButtonText="Delete Account"
    noButtonText="Cancel"
    [displayNoButton]="true"
/>