<div class="dropdown">
<a
    href="#"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
>
    <img src="/assets/account.svg" [class]="'right ' + (blackIcon ? 'black-svg' : '')" />
</a>
<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <button class="dropdown-item" (click)="logout()">Logout</button>
    <button class="dropdown-item" routerLink="/profile-options">Profile Options</button>
</div>
</div>